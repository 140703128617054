import React from "react"
import styled from "styled-components"
import anime from "animejs";
import Polygon from "../util/polygon"
import Form from "./Form"

const FormButton = styled.input`
  position: fixed;
  bottom: 20px;
  right: 20px;
  border: 4px solid #FF4000;
  border-radius: 40px;
  color: #FF4000;
  background-color: transparent;
  font-size: 18px;
  font-family: 'Comfortaa'
  font-weight: bold;
  width: 140px;
  height: 40px;
  z-index: 4;
  font-weight: 700;
  `
const SVGPolygon = styled.path`
  strokeWidth: 10; 
  fill: red;
`
const SvgStyled = styled.svg`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  display: none;
`
export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFormAnimation: null,
      hideFormAnimation: null,
      isOpen: false
    }
    this.animate = this.animate.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }
  animate(){
    if(this.state.isOpen){
      this.state.hideFormAnimation.play()
    }
    else{
      this.state.showFormAnimation.play()
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  componentDidMount() {

    let screenCenter = {
      x: parseInt(window.innerWidth/2),
      y: parseInt(window.innerHeight/2)
    }

    let screenTopRight = {
      x: parseInt(window.innerWidth),
      y: parseInt(0)
    }

    let polygon = new Polygon(5, 10, screenTopRight.x, screenTopRight.y, 0);

    let path1 = polygon.getPath();

    polygon.x = screenCenter.x;
    polygon.y = screenCenter.y;
    polygon.r = polygon.r * 5;
    polygon.rotate(Math.PI * 0.50);
    let path2 = polygon.getPath();

    polygon.rotate(Math.PI * 0.50);
    let path3 = polygon.getPath();

    polygon.r = polygon.r * 40;
    let path4 = polygon.getPath();

    document.getElementsByClassName('polygon1')[0].setAttribute('d', path1);

    let svgAnimationContainer = document.getElementsByClassName('SvgAnimationContainer')[0];
    let form = document.getElementsByClassName('form')[0];
    this.setState({
      showFormAnimation: anime({
        targets: '.polygon1',
        keyframes: [
          {d: path2, duration: 350},
          {d: path3, duration: 350},
          {d: path4, duration: 500}
        ],
        fill: '#222222',
        easing:'easeOutQuad',
        loop: false,
        autoplay: false,
        begin: ()=>{
          svgAnimationContainer.style.zIndex = 20;
          svgAnimationContainer.style.display = 'flex';
          form.style.display = 'none';
        },
        complete: ()=>{
          form.style.display = 'flex';
        }
      }),
      hideFormAnimation: anime({
        targets: '.SvgAnimationContainer',
        duration: 750,
        opacity: 0,
        easing:'easeInOutElastic',
        autoplay: false,
        complete: ()=>{
          svgAnimationContainer.style.opacity = 1;
          svgAnimationContainer.style.zIndex = -1;
          svgAnimationContainer.style.display = 'none';
          form.style.display = 'none';
        }
      })
    });
  }
  clickHandler(newState){
    this.setState(newState);
  }
  render(){
    return (
      <React.Fragment>
        <FormButton type="button" value="Get in touch!" onClick={this.animate}/>
        <SvgStyled className={"SvgAnimationContainer"} height="100%" width="100%">
          <SVGPolygon className="polygon1" />
        </SvgStyled>
        <Form className="form" isOpen={this.state.isOpen} hideFormAnimation={this.state.hideFormAnimation} clickHandler={this.clickHandler}/>
      </React.Fragment>
    );
  }
}