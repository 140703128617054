import React from "react"
import styled from "styled-components"
import ContactButtons from "./ContactButtons"

const MethodsContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  margin-bottom: 50px;
`
const Methods = styled.div`
  width: 100vw;
  height: 100vw;
  max-height: 375px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
`

const ContactMethods = ()=>{
  return(
    <MethodsContainer>
      <Methods>
      </Methods>
      <ContactButtons/>
    </MethodsContainer>
  )
}

export default ContactMethods
