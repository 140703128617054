import React, { useEffect } from "react"
import anime from "animejs"
import styled from "styled-components"

const ButtonContainer = styled.div`
  width: 187.5px;
  height: 187.5px; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const ButtonText = styled.div`
  margin-top: 15px;
`
const AnimatedCircle = styled.svg`
 position: absolute; 
`

const Button = (props)=>{
  const handleClick = ()=>{
    if(props.to) window.location.href = props.to
  }
  useEffect(()=>{
    anime({
      targets: ['.animatedCircle'],
      duration: 500,
      delay: 2000,
      easing:'easeOutQuad',
      loop: true,
      autoplay: true,
      keyframes:[
        {
          scale:1.5,
          opacity: 0,
          duration: 250,
        },
        {
          scale:1,
          opacity: 0.3,
          duration: 2500,
        }
      ],
    })
  })
  const animation = ()=>{
    if(props.to) return(
      <AnimatedCircle className="animatedCircle" height="140" width="140">
        <circle cx="70" cy="70" r="70" fill="#FFFFFF" opacity="0.3"/>
      </AnimatedCircle>
    )
  }
  return(
    <ButtonContainer onClick={handleClick}>
      {props.children}
      <ButtonText>
        {props.text}
      </ButtonText>
      {animation()}
    </ButtonContainer>
  )
}

export default Button