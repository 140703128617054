import React from "react"
import styled from "styled-components"
import Header from "../components/Header"
import InclinedText from "../components/InclinedText"
import ContactForm from "../components/ContactForm"
import PhoneSVG from "../components/homepage/PhoneSVG"
import Ecomm3Results from "../components/homepage/Ecomm3Results"
import {graphql} from "gatsby";
import SEO from "../components/SEO"
import LanguageSelector from "../components/LanguageSelector"
const locale = "en-US"
const Homepage = styled.div`
  max-width: 100vw;
  width: 100wv;
  font-family: 'Comfortaa';
  background-color: white;
  margin: 12px;
  `
const Content = styled.h1`
  margin: 38px 0px;
  font-size: 24px;
  font-weight: 300;
  `
const RedText = styled.span`
  color: #FF4000;
  font-weight: 700;
`
export default ({data}) => {
  return(
    <Homepage>
      <SEO/>
      <Header locale={locale}/>
      <Content>
        We deliver&nbsp;
        <RedText>
          award winning eCommerce stores.
        </RedText>
        <PhoneSVG data={data} />
        We help companies from around the globe to&nbsp;
        <RedText>
          get the most out of eCommerce.
        </RedText>
        {/*<Ecomm3Results data={data.allContentfulEComm3Results}/>*/}
        <InclinedText/>
      </Content>
      <LanguageSelector locale={locale}/>
      <ContactForm/>
    </Homepage>
  )
}
export const query = graphql`
  query IndexQuery{
    allContentfulFeature {
      nodes {
        title
        content
        xCoordinate
        yCoordinate
        subFeatures
        icon {
          file {
            url
          }
        }
      }
    }
    fixed: file(relativePath: {eq: "iPhone.png"}) {
      id
      childImageSharp {
        fixed(width: 360, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    allContentfulEComm3Results(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        bigText
        subtitle
        title
        node_locale
      }
    }
  }
`
