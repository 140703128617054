import React, { useEffect } from "react"
import styled from "styled-components"
import MessengerIcon from "../../icons/messenger.svg"
import WhatsAppIcon from "../../icons/whatsapp.svg"
import MailIcon from "../../icons/mail.svg"
import PhoneIcon from "../../icons/phone.svg"
import Button from "./Button"
const ButtonsContainer = styled.div`
  position: absolute;
  width: 375px;
  max-width: 375px;
  height: 375px;
  max-height: 375px;
  display: flex;
  flex-wrap: wrap;
  top: 0;
`
const MessengerStyledIcon = styled(MessengerIcon)`
  width: 50px;
  height: 50px;
`
const WhatsAppStyledIcon = styled(WhatsAppIcon)`
  width: 50px;
  height: 50px;
`
const MailStyledIcon = styled(MailIcon)`
  width: 50px;
  height: 50px;
`
const PhoneStyledIcon = styled(PhoneIcon)`
  width: 50px;
  height: 50px;
`
const ContactButtons = ()=>{
  return(
    <ButtonsContainer>
      <Button text={"Messenger"} to={"https://m.me/ecomm3"}>
        <MessengerStyledIcon/>
      </Button>
      <Button text={"+1 332 203 206 2"}>
        <PhoneStyledIcon/>
      </Button>
      <Button text={"hello@ecomm3.com"}>
        <MailStyledIcon/>
      </Button>
      <Button text={"WhatsApp"} to={"https://wa.me/13322032062"}>
        <WhatsAppStyledIcon/>
      </Button>
    </ButtonsContainer>
  )
}
export default ContactButtons