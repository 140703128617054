import React from "react"
import styled from "styled-components"
import Ticker from "./homepage/Ticker"
let Rotated = styled.div`
  padding-top: 100px;
  color: black;
  &>* {
    transform: rotate(-15deg);
    margin-bottom: 30px;
    font-weight: bold;
    overflow-y: hidden;
    height: 27px;
  }
`
export default () => (
  <Rotated>
    <Ticker duration={15000} color={'#FF0000'}>Conversion optimization</Ticker>
    <Ticker duration={20000} color={'#000000'}>UI/UX optimization</Ticker>
    <Ticker duration={6000} color={'#FF0000'}>Average Order value increment</Ticker>
  </Rotated>
)